import React from "react";
import Layout from "../components/layout/Layout";
import ListFeature from "../components/ListFeature";
import { corsiPatente } from "../data/corsi-patente/corsi-patente";
import ContactSection from "../components/home-page/ContactSection";
import Seo from "../components/layout/SEO";

const CorsiPatente = () => (
  <Layout>
    <Seo
      title={"Corsi per Patenti A B C D E | Autoscuola Faro, Pisa"}
      description={"I nostri corsi per la patente si svolgono in sede a Pisa. Scegli tra patente auto, moto, motorino, veicoli con rimorchio e altro."}
      keywords={[
        "patente A",
        "conseguimento patente Pisa",
        "lezioni di guida Pisa",
        "simulazione esame patente B",
        "conseguimento patente B",
        "patente ciclomotori",
        "patente quadricicli",
        "patente auto Pisa",
        "patente C1E",
        "patente C",
        "patente D",
        "patente D1E",
        "patente veicoli rimorchio"
      ]}
    />
    <ListFeature list={corsiPatente} />

    <ContactSection />
  </Layout>
);

export default CorsiPatente;

import { faMotorcycle, faCarSide, faTruck } from '@fortawesome/free-solid-svg-icons';
export const corsiPatente = {
    title: "I nostri corsi per patenti A, B, C, D",
    subtitle: "Presso la nostra autoscuola a Pisa puoi seguire i corsi di teoria e pratica per conseguire la patente di guida per auto, moto, veicoli trainanti rimorchio o per il trasporto di persone:",
    list: [
        {
            id: "pat1",
            title: "Patente AM",
            link: "/corsi-patente/patenteAM",
            icon: faMotorcycle,
        },
        {
            id: "pat2",
            title: "Patente A1, A2, A3",
            link: "/corsi-patente/patenti-A",
            icon: faMotorcycle,
        },
        {
            id: "pat3",
            title: "Patente B",
            link: "/corsi-patente/patenteB",
            icon: faCarSide,
        },
        {
            id: "pat4",
            title: "Patente B96 e BE",
            link: "/corsi-patente/patenti-B96",
            icon: faCarSide,
        },
        {
            id: "pat5",
            title: "Patenti C1, C1E, C e CE",
            link: "/corsi-patente/patenti-C",
            icon: faTruck,
        },
        {
            id: "pat6",
            title: "Patente D1, D1E, D e DE",
            link: "/corsi-patente/patenti-D",
            icon: faTruck,
        },
    ],
    buttonLink: "/orari/#orario-corsi-teorici",
    buttonText: "Orario corsi"
}